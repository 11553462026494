const config = {
  AUTH_API_URL: "https://api.nomoex.com",
  COINPRICE_API_URL: "https://api.nomoex.com",
  ORDERS_API_URL: "https://api.nomoex.com",
  COMMONSERVICE_API_URL: "https://api.nomoex.com",
  // AUTH_API_URL: "http://localhost:8001",
  // COINPRICE_API_URL: "http://localhost:8002",
  // ORDERS_API_URL: "http://localhost:8004",
  // COMMONSERVICE_API_URL: "http://localhost:8005",
  // AUTH_API_URL: "http://stage-nomoex-237694941.ap-south-1.elb.amazonaws.com",
  // COINPRICE_API_URL: "http://stage-nomoex-237694941.ap-south-1.elb.amazonaws.com",
  // ORDERS_API_URL: "http://stage-nomoex-237694941.ap-south-1.elb.amazonaws.com",
  // COMMONSERVICE_API_URL: "http://stage-nomoex-237694941.ap-south-1.elb.amazonaws.com",
};

export default config;
