import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { MdOpenInNew } from "react-icons/md";

import TransactionFilter from "../transactions/TransactionFilter";
import { cleanObject } from "../../utils/helper";
import commonStyles from "../../styles/common/Common.module.scss";
import { Table } from "antd";

const UserTransactions = ({
  transactionsData,
  total,
  loading,
  tableParams,
  setTableParams,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(transactionsData);
  }, [transactionsData]);

  const columns = [
    {
      title: "Txn Id",
      dataIndex: "txnId",
      sorter: true,
    },
    {
      title: "Date",
      dataIndex: "txnDate",
      render: (txnDate) => moment(txnDate).format("DD MMM YYYY"),
    },
    {
      title: "Type",
      dataIndex: "txnType",
      filters: [
        {
          text: "Deposit",
          value: "deposit",
        },
        {
          text: "Withdraw",
          value: "withdraw",
        },
        {
          text: "Vaultx Invest",
          value: "vInvest",
        },
        {
          text: "Vaultx Redeem",
          value: "vRedeem",
        },
        {
          text: "Fiat",
          value: "fiat",
        },
      ],
      render: (txnType) =>
        txnType === "deposit"
          ? "Deposit"
          : txnType === "withdraw"
          ? "Withdraw"
          : txnType === "vInvest"
          ? "Vaultx Invest"
          : txnType === "vRedeem"
          ? "Vaultx Redeem"
          : txnType === "fiat"
          ? "Fiat"
          : "",
    },
    {
      title: "Txn For",
      dataIndex: "txnFor",
      filters: [
        {
          text: "Crypto",
          value: "crypto",
        },
        {
          text: "Fiat",
          value: "fiat",
        },
      ],
      render: (txnFor) =>
        txnFor === "crypto" ? "Crypto" : txnFor === "fiat" ? "Fiat" : "",
    },
    {
      title: "Status",
      dataIndex: "txnStatus",
      filters: [
        {
          text: "Initiated",
          value: "IN",
        },
        {
          text: "In Progress",
          value: "IP",
        },
        {
          text: "Failed",
          value: "F",
        },
        {
          text: "Success",
          value: "S",
        },
      ],
      render: (txnStatus) =>
        txnStatus === "IN"
          ? "Initiated"
          : txnStatus === "IP"
          ? "In Progress"
          : txnStatus === "F"
          ? "Failed"
          : txnStatus === "S"
          ? "Success"
          : "",
    },
    {
      title: "Amount",
      dataIndex: "txnAmount",
    },
    {
      title: "Actions",
      render: (record) => (
        <Link to={`/transactions/${record.txnId}`}>
          <MdOpenInNew className={commonStyles.defaultIconStyle} />
        </Link>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevState) => ({
      pagination: {
        page: pagination.current,
        limit: pagination.pageSize,
        orderBy: sorter?.order ? sorter.field : "_id",
        sort: sorter?.order === "ascend" ? "asc" : "desc",
      },
      total: prevState.total,
      filters: cleanObject({ ...prevState.filters, ...filters }),
    }));

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.limit) {
      setData([]);
    }
  };

  return (
    <>
      <TransactionFilter
        tableParams={tableParams}
        setTableParams={setTableParams}
      />
      <Table
        columns={columns}
        rowKey={(record) => record.txnId}
        dataSource={data}
        pagination={{
          current: tableParams.pagination?.page,
          pageSize: tableParams.pagination?.limit,
          total: total,
        }}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default UserTransactions;
