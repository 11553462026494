import { Input } from "antd";
import styles from "../../styles/common/Common.module.scss";

const InputPassword = ({
  placeholder,
  value,
  onChange,
  className,
  onPressEnter,
}) => {
  const inputTextStyle = className
    ? [styles.inputText, className].join(" ")
    : styles.inputText;

  return (
    <Input.Password
      size="large"
      placeholder={placeholder || null}
      value={value || null}
      onChange={onChange}
      onPressEnter={onPressEnter}
      className={inputTextStyle}
    />
  );
};

export default InputPassword;
