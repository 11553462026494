import { Route, Routes, Navigate } from "react-router-dom";
import OrdersPage from "../pages/orders/OrdersPage";
import OneOrderPage from "../pages/orders/OneOrderPage";

const OrdersRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<OrdersPage />} />
      <Route path="/:orderId" element={<OneOrderPage />} />
      <Route path="*" element={<Navigate replace to="" />} />
    </Routes>
  );
};

export default OrdersRoute;
