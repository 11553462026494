import config from "../utils/config";
import { createQueryString } from "../utils/helper";
import { updateFiatTransaction } from "../validators/transactionsValidator";

export const getAllTransactionAPI = async (get, queryParam) => {
  const queryString = createQueryString(queryParam);
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/operator/txns${queryString}`
  );
  return response;
};

export const getOneTransactionAPI = async (get, txnId) => {
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/operator/txn/${txnId}`
  );
  return response;
};

export const updateFiatTransactionAPI = async (patch, txnId, data) => {
  const validate = updateFiatTransaction(data);

  if (validate.success) {
    const response = await patch(
      `${config.AUTH_API_URL}/api/v1/orders/operator/fiat/update-status/${txnId}`,
      data
    );

    return response;
  } else {
    return validate;
  }
};
