import { Route, Routes, Navigate } from "react-router-dom";
import UsersPage from "../pages/users/UsersPage";
import OneUserPage from "../pages/users/OneUserPage";

const UsersRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<UsersPage />} />
      <Route path="/:userId" element={<OneUserPage />} />
      <Route path="*" element={<Navigate replace to="" />} />
    </Routes>
  );
};

export default UsersRoute;
