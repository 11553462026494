import { Link } from "react-router-dom";
import PageHeading from "../../components/common/PageHeading";
import ProvideNomoexTokenForm from "../../components/nomoexServices/ProvideNomoexTokenForm";
import { useEffect, useState } from "react";
import { provideNomoexTokenAPI } from "../../apis/nomoexServicesAPIs";
import useAPICall from "../../hooks/useAPICall";
import { notification } from "antd";
import { getOneUserByUidAPI } from "../../apis/userAPIs";

const ProvideNomoexTokenPage = () => {
  const { get, post } = useAPICall();
  const [api, contextHolder] = notification.useNotification();
  const [tokenData, setTokenData] = useState({
    userUID: "",
    holdings: "",
    orderPrice: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const provideTokenHandler = async () => {
    setLoading(true);
    const data = {
      userId: selectedUser._id,
      holdings: tokenData.holdings,
      orderPrice: tokenData.orderPrice,
    };
    const response = await provideNomoexTokenAPI(post, data);
    if (response.success) {
      setTokenData({
        userUID: "",
        holdings: "",
        orderPrice: "",
      });
      setSelectedUser({});
      api["success"]({
        message: "Success!!",
        description: response.message,
      });
    } else {
      api["error"]({
        message: "Error!!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  const fetchUser = async () => {
    setLoading(true);
    const response = await getOneUserByUidAPI(get, tokenData.userUID);
    if (response.success) {
      setSelectedUser(response.data);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUser();
    };

    if (tokenData.userUID?.length > 11) fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenData.userUID]);

  return (
    <>
      {contextHolder}
      <PageHeading
        breadcrumb={[
          <Link to="/nomoex-services">Nomoex Services</Link>,
          " > Provide Nomoex Token",
        ]}
        heading={"Provide Nomoex Token"}
        isAddNew={false}
      />
      <ProvideNomoexTokenForm
        loading={loading}
        tokenData={tokenData}
        setTokenData={setTokenData}
        selectedUser={selectedUser}
        provideTokenHandler={provideTokenHandler}
      />
    </>
  );
};

export default ProvideNomoexTokenPage;
