import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { notification } from "antd";

import styles from "../../styles/transactions/Transactions.module.scss";
import PageHeading from "../../components/common/PageHeading";
import useAPICall from "../../hooks/useAPICall";
import {
  getOneTransactionAPI,
  updateFiatTransactionAPI,
} from "../../apis/transactionAPIs";
import OneTransactionActions from "../../components/transactions/OneTransactionActions";
import OneTransactionDetails from "../../components/transactions/OneTransactionDetails";
import OneTransactionUserDetails from "../../components/transactions/OneTransactionUserDetails";
import OneTransactionOtherInfo from "../../components/transactions/OneTransactionOtherInfo";

const OneTransactionsPage = () => {
  const { get, patch } = useAPICall();
  const param = useParams();
  const [api, contextHolder] = notification.useNotification();

  const txnId = param.txnId;
  const [transactionData, setTransactionData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchTransaction = async () => {
    setLoading(true);
    const response = await getOneTransactionAPI(get, txnId);
    if (response.success) {
      setTransactionData(response.data);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txnId]);

  const reloadHandler = async () => {
    await fetchTransaction();
  };

  const markTxnAsFailed = async () => {
    setLoading(true);
    const data = {
      isKycDone: "F",
    };
    const response = await updateFiatTransactionAPI(patch, txnId, data);
    if (response.success) {
      api["success"]({
        message: "Success!",
        description: response.message,
      });
      await fetchTransaction();
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  const markTxnAsSuccess = async () => {
    setLoading(true);
    const data = {
      isKycDone: "S",
    };
    const response = await updateFiatTransactionAPI(patch, txnId, data);
    if (response.success) {
      api["success"]({
        message: "Success!",
        description: response.message,
      });
      await fetchTransaction();
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <PageHeading
        breadcrumb={[
          <Link to="/transactions">Transactions</Link>,
          " > ",
          txnId,
        ]}
        heading={`Transactions - ${txnId}`}
        isAddNew={false}
        reloadHandler={reloadHandler}
        showRefresh={true}
      />
      {transactionData.txnFor &&
        transactionData.txnFor === "fiat" &&
        transactionData.txnStatus &&
        transactionData.txnStatus === "IP" && (
          <OneTransactionActions
            loading={loading}
            markTxnAsFailed={markTxnAsFailed}
            markTxnAsSuccess={markTxnAsSuccess}
          />
        )}
      <div className={styles.txnDetailsContainer}>
        <OneTransactionDetails transactionData={transactionData} />
        <OneTransactionUserDetails transactionData={transactionData} />
        <OneTransactionOtherInfo transactionData={transactionData} />
      </div>
    </>
  );
};

export default OneTransactionsPage;
