import config from "../utils/config";
import {
  updateUSDTPrice,
  updateAppVersion,
} from "../validators/appDataValidator";

export const getUSDTPriceAPI = async (get) => {
  const response = await get(
    `${config.COMMONSERVICE_API_URL}/api/v1/common-services/operator/appdata/usdt-price`
  );
  return response;
};

export const updateUSDTPriceAPI = async (post, data) => {
  const validate = updateUSDTPrice(data);

  if (validate.success) {
    const response = await post(
      `${config.AUTH_API_URL}/api/v1/common-services/operator/appdata/update-usdt-price`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const getAppVersionAPI = async (get) => {
  const response = await get(
    `${config.COMMONSERVICE_API_URL}/api/v1/common-services/operator/appdata/app-version`
  );
  return response;
};

export const updateAppVersionAPI = async (post, data) => {
  const validate = updateAppVersion(data);

  if (validate.success) {
    const response = await post(
      `${config.AUTH_API_URL}/api/v1/common-services/operator/appdata/update-app-version`,
      data
    );

    return response;
  } else {
    return validate;
  }
};
