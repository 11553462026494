import { Button, Card } from "antd";
import InputText from "../common/InputText";
import styles from "../../styles/auth/Login.module.scss";
import InputPassword from "../common/InputPassword";

const Login = ({ loading, loginData, setLoginData, loginHandler }) => {
  return (
    <div className={styles.loginContainer}>
      <Card className={styles.card}>
        <div className={styles.logoContainer}>
          <img
            src="https://webapp-nomoex.s3.ap-south-1.amazonaws.com/nomoex_name/nomoex_name.png"
            alt="nomoex-logo"
            className={styles.logo}
          />
        </div>
        <div className={styles.formItem}>
          <p className={styles.formTitle}>Email:</p>
          <InputText
            placeholder={"Enter email address"}
            value={loginData.email}
            onChange={(e) =>
              setLoginData((prevState) => ({
                ...prevState,
                email: e.target.value,
              }))
            }
          />
        </div>
        <div className={styles.formItem}>
          <p className={styles.formTitle}>Password:</p>
          <InputPassword
            placeholder={"Enter password"}
            value={loginData.password}
            onChange={(e) =>
              setLoginData((prevState) => ({
                ...prevState,
                password: e.target.value,
              }))
            }
            onPressEnter={loginHandler}
          />
        </div>
        <Button
          loading={loading}
          className={styles.loginButton}
          onClick={loginHandler}
        >
          Login
        </Button>
      </Card>
    </div>
  );
};

export default Login;
