import { Button } from "antd";
import styles from "../../../styles/users/Users.module.scss";
import commonStyles from "../../../styles/common/Common.module.scss";

const OneUserKYCActions = ({ loading, markKYCAsFailed, markKYCAsSuccess }) => {
  return (
    <div className={styles.userKYCActions}>
      <div className={commonStyles.actionButtons}>
        <Button
          loading={loading}
          className={commonStyles.primaryButton}
          onClick={markKYCAsFailed}
        >
          Mark KYC as Failed
        </Button>
        <Button
          loading={loading}
          className={commonStyles.primaryButton}
          onClick={markKYCAsSuccess}
        >
          Mark KYC as Success
        </Button>
      </div>
    </div>
  );
};

export default OneUserKYCActions;
