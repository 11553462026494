import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Tabs, notification } from "antd";

import PageHeading from "../../components/common/PageHeading";
import useAPICall from "../../hooks/useAPICall";
import {
  getOneUserByUserIdAPI,
  getOneUserKYCDataAPI,
  updateKYCStatusAPI,
} from "../../apis/userAPIs";
import { getAllOrderAPI } from "../../apis/orderHistoryAPIs";
import { getAllTransactionAPI } from "../../apis/transactionAPIs";
import {
  getNomoexHoldingsAPI,
  getPortfolioAPI,
} from "../../apis/portfolioAPIs";

import UserDetails from "../../components/users/UserDetails";
import UserTransactions from "../../components/users/UserTransactions";
import UserOrders from "../../components/users/UserOrders";
import UserPortfolio from "../../components/users/UserPortfolio";

const OneUserPage = () => {
  const { get, patch } = useAPICall();
  const param = useParams();
  const [api, contextHolder] = notification.useNotification();

  const userId = param.userId;
  const [userData, setUserData] = useState({});
  const [kycData, setKYCData] = useState({});
  const [loading, setLoading] = useState(false);

  const [portfolio, setPortfolio] = useState({});
  const [nomoexHoldings, setNomoexHoldings] = useState({});

  const [ordersData, setOrdersData] = useState([]);
  const [ordersTotal, setOrdersTotal] = useState(0);

  const [transactionsData, setTransactionsData] = useState([]);
  const [transactionsTotal, setTransactionsTotal] = useState(0);

  const [orderTableParams, setOrderTableParams] = useState({
    pagination: {
      page: 1,
      limit: 10,
      orderBy: "_id",
      sort: "desc",
    },
    filters: { userId: [userId] },
  });

  const [txnTableParams, setTxnTableParams] = useState({
    pagination: {
      page: 1,
      limit: 10,
      orderBy: "_id",
      sort: "desc",
    },
    filters: { userId: [userId] },
  });

  const fetchUser = async () => {
    setLoading(true);
    const response = await getOneUserByUserIdAPI(get, userId);
    if (response.success) {
      setUserData(response.data);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  const fetchKYCData = async () => {
    setLoading(true);
    const response = await getOneUserKYCDataAPI(get, userId);
    if (response.success) {
      setKYCData(response.data);
    } else {
      // api["error"]({
      //   message: "Error!",
      //   description: response.message,
      // });
    }
    setLoading(false);
  };

  const fetchAllTransactions = async () => {
    setLoading(true);
    const response = await getAllTransactionAPI(get, txnTableParams);
    if (response.success) {
      setTransactionsData(response.data);
      setTransactionsTotal(response.metaData.total);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  const fetchAllOrders = async () => {
    setLoading(true);
    const response = await getAllOrderAPI(get, orderTableParams);
    if (response.success) {
      setOrdersData(response.data);
      setOrdersTotal(response.metaData.total);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  const fetchPortfolio = async () => {
    setLoading(true);
    const response = await getPortfolioAPI(get, userId);
    if (response.success) {
      setPortfolio(response.data);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  const fetchNomoexHoldings = async () => {
    setLoading(true);
    const response = await getNomoexHoldingsAPI(get, userId);
    if (response.success) {
      setNomoexHoldings(response.data);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUser();
      await fetchKYCData();
      await fetchPortfolio();
      await fetchNomoexHoldings();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    fetchAllTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(txnTableParams)]);

  useEffect(() => {
    fetchAllOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(orderTableParams)]);

  const reloadHandler = async () => {
    await fetchUser();
    await fetchKYCData();
    await fetchPortfolio();
    await fetchNomoexHoldings();
    await fetchAllTransactions();
    await fetchAllOrders();
  };

  const markKYCAsFailed = async () => {
    setLoading(true);
    const data = {
      isKycDone: "F",
    };
    const response = await updateKYCStatusAPI(patch, userId, data);
    if (response.success) {
      setUserData(response.data);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  const markKYCAsSuccess = async () => {
    setLoading(true);
    const data = {
      isKycDone: "S",
    };
    const response = await updateKYCStatusAPI(patch, userId, data);
    if (response.success) {
      setUserData(response.data);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  const tabItems = [
    {
      key: "1",
      label: `User Details`,
      children: (
        <UserDetails
          userData={userData}
          kycData={kycData}
          loading={loading}
          markKYCAsFailed={markKYCAsFailed}
          markKYCAsSuccess={markKYCAsSuccess}
        />
      ),
    },
    {
      key: "2",
      label: `Portfolio`,
      children: (
        <UserPortfolio portfolio={portfolio} nomoexHoldings={nomoexHoldings} />
      ),
    },
    {
      key: "3",
      label: `Orders (${ordersTotal})`,
      children: (
        <UserOrders
          ordersData={ordersData}
          total={ordersTotal}
          loading={loading}
          tableParams={orderTableParams}
          setTableParams={setOrderTableParams}
        />
      ),
    },
    {
      key: "4",
      label: `Transactions (${transactionsTotal})`,
      children: (
        <UserTransactions
          transactionsData={transactionsData}
          total={transactionsTotal}
          loading={loading}
          tableParams={txnTableParams}
          setTableParams={setTxnTableParams}
        />
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <PageHeading
        breadcrumb={[<Link to="/users">Users</Link>, " > ", userData.uid]}
        heading={`User - ${userData.uid}`}
        isAddNew={false}
        reloadHandler={reloadHandler}
        showRefresh={true}
      />
      <Tabs defaultActiveKey="1" items={tabItems} />
    </>
  );
};

export default OneUserPage;
