import { Card, Image } from "antd";
import styles from "../../../styles/users/Users.module.scss";
import nomoexLoader from "../../../assets/nomoexLoader.gif";

const OneUserKYCImages = ({ kycData, loading }) => {
  console.log(kycData.govIdFrontImage);
  return (
    <Card className={styles.card}>
      <h3>KYC Images</h3>
      <hr />
      <div className={styles.images}>
        {kycData.govIdFrontImage && (
          <div className={styles.imageContainer}>
            <Image
              src={kycData.govIdFrontImage}
              width={100}
              height={100}
              alt="Govt Id Front"
              placeholder={
                <Image
                  preview={false}
                  src={nomoexLoader}
                  width={100}
                  alt="nomoex-loader"
                />
              }
            />
            <p className={styles.imageCaption}>Govt Id Front</p>
          </div>
        )}
        {kycData.govIdBackImage && (
          <div className={styles.imageContainer}>
            <Image
              src={kycData.govIdBackImage}
              width={100}
              height={100}
              alt="Govt Id Back"
              placeholder={
                <Image
                  preview={false}
                  src={nomoexLoader}
                  width={100}
                  alt="nomoex-loader"
                />
              }
            />
            <p className={styles.imageCaption}>Govt Id Back</p>
          </div>
        )}
      </div>
      {kycData.otpImage && (
        <div className={styles.imageContainer}>
          <Image
            src={kycData.otpImage}
            width={100}
            height={100}
            alt="OTP Image"
            placeholder={
              <Image
                preview={false}
                src={nomoexLoader}
                width={100}
                alt="nomoex-loader"
              />
            }
          />
          <p className={styles.imageCaption}>OTP Image</p>
        </div>
      )}
    </Card>
  );
};

export default OneUserKYCImages;
