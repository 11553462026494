import config from "../utils/config";

export const getPortfolioAPI = async (get, userId) => {
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/operator/${userId}/portfolio`
  );
  return response;
};

export const getNomoexHoldingsAPI = async (get, userId) => {
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/operator/${userId}/nomox-holdings`
  );
  return response;
};
