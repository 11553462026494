import { Input } from "antd";
import styles from "../../styles/common/Common.module.scss";

const InputText = ({
  placeholder,
  value,
  onChange,
  className,
  maxLength,
  disabled,
  prefix,
  suffix,
}) => {
  const inputTextStyle = className
    ? [styles.inputText, className].join(" ")
    : styles.inputText;

  return (
    <Input
      size="large"
      placeholder={placeholder || null}
      value={value || null}
      onChange={onChange}
      className={inputTextStyle}
      maxLength={maxLength}
      disabled={disabled || false}
      prefix={prefix || null}
      suffix={suffix || null}
    />
  );
};

export default InputText;
