import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { MdOpenInNew } from "react-icons/md";

import PageHeading from "../../components/common/PageHeading";
import { cleanObject } from "../../utils/helper";
import commonStyles from "../../styles/common/Common.module.scss";
import { Table, notification } from "antd";
import useAPICall from "../../hooks/useAPICall";
import { getAllOrderAPI } from "../../apis/orderHistoryAPIs";
import OrderFilter from "../../components/orders/OrderFilter";

const OrdersPage = () => {
  const { get } = useAPICall();
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      page: 1,
      limit: 10,
      orderBy: "_id",
      sort: "desc",
    },
    filters: {},
  });

  const fetchAllOrders = async () => {
    setLoading(true);
    const response = await getAllOrderAPI(get, tableParams);
    if (response.success) {
      setData(response.data);
      setTotal(response.metaData.total);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams)]);

  const reloadHandler = async () => {
    await fetchAllOrders();
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: "orderId",
      sorter: true,
    },
    {
      title: "Date",
      dataIndex: "orderDate",
      render: (orderDate) => moment(orderDate).format("DD MMM YYYY"),
    },
    {
      title: "Type",
      dataIndex: "orderType",
      filters: [
        {
          text: "Buy",
          value: "buy",
        },
        {
          text: "Sell",
          value: "sell",
        },
        {
          text: "Nomox",
          value: "nomox",
        },
      ],
      render: (txnType) =>
        txnType === "buy"
          ? "Buy"
          : txnType === "sell"
          ? "Sell"
          : txnType === "nomox"
          ? "Nomox"
          : "",
    },
    {
      title: "Status",
      dataIndex: "orderStatus",
      filters: [
        {
          text: "In Progress",
          value: "IP",
        },
        {
          text: "Failed",
          value: "F",
        },
        {
          text: "Success",
          value: "S",
        },
      ],
      render: (orderStatus) =>
        orderStatus === "IP"
          ? "In Progress"
          : orderStatus === "F"
          ? "Failed"
          : orderStatus === "S"
          ? "Success"
          : "",
    },
    {
      title: "User",
      render: (record) =>
        `${record.userDetail.name} (${record.userDetail.email})`,
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
    },
    {
      title: "Holdings",
      dataIndex: "holdings",
    },
    {
      title: "Actions",
      render: (record) => (
        <Link to={`/orders/${record.orderId}`}>
          <MdOpenInNew className={commonStyles.defaultIconStyle} />
        </Link>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevState) => ({
      pagination: {
        page: pagination.current,
        limit: pagination.pageSize,
        orderBy: sorter?.order ? sorter.field : "_id",
        sort: sorter?.order === "ascend" ? "asc" : "desc",
      },
      total: prevState.total,
      filters: cleanObject({ ...prevState.filters, ...filters }),
    }));

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.limit) {
      setData([]);
    }
  };

  return (
    <>
      {contextHolder}
      <PageHeading
        breadcrumb={"Orders"}
        heading={`Orders (${total})`}
        isAddNew={false}
        reloadHandler={reloadHandler}
        showRefresh={true}
      />
      <OrderFilter tableParams={tableParams} setTableParams={setTableParams} />
      <Table
        columns={columns}
        rowKey={(record) => record.txnId}
        dataSource={data}
        pagination={{
          current: tableParams.pagination?.page,
          pageSize: tableParams.pagination?.limit,
          total: total,
        }}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default OrdersPage;
