import jwtDecode from "jwt-decode";

const initialState = {
  token: null,
  userName: "",
  isLoggedIn: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ACCESS_TOKEN":
      localStorage.setItem("auth-token", action.payload.token);
      localStorage.setItem("loggedin-status", true);
      return {
        ...state,
        token: action.payload.token,
        userName: action.payload.userName,
        isLoggedIn: true,
      };
    case "LOGOUT":
      localStorage.removeItem("auth-token");
      localStorage.removeItem("loggedin-status");
      return {
        ...state,
        token: null,
        userName: "",
        isLoggedIn: false,
      };
    default: {
      const token = localStorage.getItem("auth-token");
      const isLoggedIn = localStorage.getItem("loggedin-status");
      let userName = "";

      if (token) {
        const tokenData = jwtDecode(token);
        userName = tokenData.userName;
      }

      return {
        ...state,
        token: token || null,
        userName: userName || "",
        isLoggedIn: isLoggedIn || false,
      };
    }
  }
};

export default authReducer;
