import { Card } from "antd";
import styles from "../../styles/transactions/Transactions.module.scss";

const OneTransactionOtherInfo = ({ transactionData }) => {
  return (
    <Card className={styles.card}>
      <h3>Other Details</h3>
      <hr />
      {Object.entries(transactionData.otherInfo || {}).map((el, i) => (
        <div key={i} className={styles.detailContainer}>
          <p className={styles.detailTitle}>{el[0]}</p>
          <p className={styles.detailValue}>{el[1]}</p>
        </div>
      ))}
    </Card>
  );
};

export default OneTransactionOtherInfo;
