import { AiOutlineArrowRight } from "react-icons/ai";
import styles from "../../styles/nomoexServices/NomoexServices.module.scss";
import { Link } from "react-router-dom";

const NomoexServicesList = () => {
  return (
    <>
      <Link to="/nomoex-services/provide-nomoex-token">
        <div className={styles.listItemContainer}>
          <h3>Provide Nomoex Token</h3>
          <AiOutlineArrowRight className={styles.arrowIcon} />
        </div>
      </Link>
    </>
  );
};

export default NomoexServicesList;
