const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const login = (data) => {
  if (
    !data.email ||
    !data.email.toString().toLowerCase().trim().match(emailRegex)
  ) {
    return {
      success: false,
      message: "Invalid email address.",
    };
  }
  if (!data.password || data.password.length < 6) {
    return {
      success: false,
      message: "Invalid password.",
    };
  }
  return { success: true };
};
