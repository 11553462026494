import { useEffect, useState } from "react";
import { Button } from "antd";

import styles from "../../styles/appData/AppData.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";
import InputText from "../common/InputText";

const AppVersionSection = ({
  loading,
  appVersion,
  setAppVersion,
  updateAppVersion,
}) => {
  const [editAppVersion, setEditAppVersion] = useState(false);
  const [prevValue, setPrevValue] = useState("");

  const editAppVersionHandler = () => {
    setEditAppVersion(true);
  };

  const cancelEditAppVersionHandler = () => {
    setAppVersion(prevValue);
    setEditAppVersion(false);
  };

  const updateAppVersionHandler = async () => {
    setPrevValue(appVersion);
    await updateAppVersion();
    setEditAppVersion(false);
  };

  useEffect(() => {
    setPrevValue(appVersion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.sectionContainer}>
        <h2 className={styles.sectionHeading}>App Version</h2>
        <div className={styles.detailsContainer}>
          <div className={styles.details}>
            <p className={styles.detailTitle}>Version:</p>
            <InputText
              placeholder={"0.0.0"}
              value={appVersion}
              onChange={(e) => setAppVersion(e.target.value)}
              disabled={!editAppVersion}
            />
          </div>
          <div className={commonStyles.actionButtons}>
            {!editAppVersion ? (
              <Button
                loading={loading}
                className={commonStyles.primaryButton}
                onClick={editAppVersionHandler}
              >
                Edit
              </Button>
            ) : (
              <>
                <Button
                  loading={loading}
                  className={commonStyles.secondaryButton}
                  onClick={cancelEditAppVersionHandler}
                >
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  className={commonStyles.primaryButton}
                  onClick={updateAppVersionHandler}
                >
                  Update
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppVersionSection;
