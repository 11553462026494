import { Card } from "antd";
import styles from "../../styles/orders/Orders.module.scss";
import moment from "moment";

const OneOrderDetails = ({ orderData }) => {
  return (
    <Card className={styles.card}>
      <h3>Order Details</h3>
      <hr />
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Coin</p>
        <p className={styles.detailValue}>
          {`${orderData.coinDetail?.coinName} (${orderData.coinDetail?.coinSymbol})` ||
            "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Date</p>
        <p className={styles.detailValue}>
          {moment(orderData.txnDate).format("DD MMM YYYY, hh:mm a")}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Status</p>
        <p className={styles.detailValue}>
          {orderData.orderStatus === "IP"
            ? "In Progress"
            : orderData.orderStatus === "F"
            ? "Failed"
            : orderData.orderStatus === "S"
            ? "Success"
            : "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Order Type</p>
        <p className={styles.detailValue}>
          {orderData.orderType === "buy"
            ? "Buy"
            : orderData.orderType === "sell"
            ? "Sell"
            : orderData.orderType === "nomox"
            ? "Nomox"
            : "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Order Method</p>
        <p className={styles.detailValue}>
          {orderData.isLimit === true
            ? "Limit"
            : orderData.isLimit === false
            ? "Instant"
            : "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Holdings</p>
        <p className={styles.detailValue}>{orderData.holdings || "-"}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Order Price</p>
        <p className={styles.detailValue}>{orderData.orderPrice || "-"}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Amount</p>
        <p className={styles.detailValue}>{orderData.totalAmount || "-"}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Fee</p>
        <p className={styles.detailValue}>{orderData.fee || "-"}</p>
      </div>
    </Card>
  );
};

export default OneOrderDetails;
