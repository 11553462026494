import { useEffect, useState } from "react";
import { notification } from "antd";
import PageHeading from "../../components/common/PageHeading";
import USDTPriceSection from "../../components/appData/USDTPriceSection";
import AppVersionSection from "../../components/appData/AppVersionSection";
import useAPICall from "../../hooks/useAPICall";
import {
  getAppVersionAPI,
  getUSDTPriceAPI,
  updateAppVersionAPI,
  updateUSDTPriceAPI,
} from "../../apis/appDataAPIs";

const AppDataPage = () => {
  const { get, post } = useAPICall();
  const [api, contextHolder] = notification.useNotification();
  const [usdtPrice, setUsdtPrice] = useState(0);
  const [appVersion, setAppVersion] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchUSDTPrice = async () => {
    setLoading(true);
    const response = await getUSDTPriceAPI(get);
    if (response.success) {
      setUsdtPrice(response.data);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  const fetchAppVersion = async () => {
    setLoading(true);
    const response = await getAppVersionAPI(get);
    if (response.success) {
      setAppVersion(response.data);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchUSDTPrice();
      await fetchAppVersion();
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUSDTPrice = async () => {
    setLoading(true);
    const data = {
      price: usdtPrice,
    };

    const response = await updateUSDTPriceAPI(post, data);
    if (response.success) {
      api["success"]({
        message: "Success!!",
        description: response.message,
      });
    } else {
      api["error"]({
        message: "Error!!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  const updateAppVersion = async () => {
    setLoading(true);
    const data = {
      version: appVersion,
    };

    const response = await updateAppVersionAPI(post, data);
    if (response.success) {
      api["success"]({
        message: "Success!!",
        description: response.message,
      });
    } else {
      api["error"]({
        message: "Error!!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <PageHeading
        breadcrumb={"App Data"}
        heading={"App Data"}
        isAddNew={false}
      />
      <USDTPriceSection
        loading={loading}
        usdtPrice={usdtPrice}
        setUsdtPrice={setUsdtPrice}
        updateUSDTPrice={updateUSDTPrice}
      />
      <AppVersionSection
        loading={loading}
        appVersion={appVersion}
        setAppVersion={setAppVersion}
        updateAppVersion={updateAppVersion}
      />
    </>
  );
};

export default AppDataPage;
