import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { MdOpenInNew } from "react-icons/md";

import { cleanObject } from "../../utils/helper";
import commonStyles from "../../styles/common/Common.module.scss";
import { Table } from "antd";
import OrderFilter from "../../components/orders/OrderFilter";

const UserOrders = ({
  ordersData,
  total,
  loading,
  tableParams,
  setTableParams,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(ordersData);
  }, [ordersData]);

  const columns = [
    {
      title: "Order Id",
      dataIndex: "orderId",
      sorter: true,
    },
    {
      title: "Date",
      dataIndex: "orderDate",
      render: (orderDate) => moment(orderDate).format("DD MMM YYYY"),
    },
    {
      title: "Type",
      dataIndex: "orderType",
      filters: [
        {
          text: "Buy",
          value: "buy",
        },
        {
          text: "Sell",
          value: "sell",
        },
        {
          text: "Nomox",
          value: "nomox",
        },
      ],
      render: (txnType) =>
        txnType === "buy"
          ? "Buy"
          : txnType === "sell"
          ? "Sell"
          : txnType === "nomox"
          ? "Nomox"
          : "",
    },
    {
      title: "Status",
      dataIndex: "orderStatus",
      filters: [
        {
          text: "In Progress",
          value: "IP",
        },
        {
          text: "Failed",
          value: "F",
        },
        {
          text: "Success",
          value: "S",
        },
      ],
      render: (orderStatus) =>
        orderStatus === "IP"
          ? "In Progress"
          : orderStatus === "F"
          ? "Failed"
          : orderStatus === "S"
          ? "Success"
          : "",
    },
    {
      title: "Amount",
      dataIndex: "totalAmount",
    },
    {
      title: "Holdings",
      dataIndex: "holdings",
    },
    {
      title: "Actions",
      render: (record) => (
        <Link to={`/orders/${record.orderId}`}>
          <MdOpenInNew className={commonStyles.defaultIconStyle} />
        </Link>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevState) => ({
      pagination: {
        page: pagination.current,
        limit: pagination.pageSize,
        orderBy: sorter?.order ? sorter.field : "_id",
        sort: sorter?.order === "ascend" ? "asc" : "desc",
      },
      total: prevState.total,
      filters: cleanObject({ ...prevState.filters, ...filters }),
    }));

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.limit) {
      setData([]);
    }
  };

  return (
    <>
      <OrderFilter tableParams={tableParams} setTableParams={setTableParams} />
      <Table
        columns={columns}
        rowKey={(record) => record.txnId}
        dataSource={data}
        pagination={{
          current: tableParams.pagination?.page,
          pageSize: tableParams.pagination?.limit,
          total: total,
        }}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default UserOrders;
