import InputText from "../common/InputText";

import filterStyles from "../../styles/common/Filter.module.scss";
import { cleanObject } from "../../utils/helper";

const UserFilter = ({ tableParams, setTableParams }) => {
  return (
    <div className={filterStyles.filterContainer}>
      <div className={filterStyles.filterProperty}>
        <p className={filterStyles.propertyTitle}>UID:</p>
        <InputText
          placeholder={"UID"}
          value={tableParams.filters.uid || ""}
          onChange={(e) =>
            setTableParams((prevState) => ({
              ...prevState,
              filters: cleanObject({
                ...prevState.filters,
                uid: e.target.value ? [e.target.value] : null,
              }),
            }))
          }
        />
      </div>
      <div className={filterStyles.filterProperty}>
        <p className={filterStyles.propertyTitle}>Email:</p>
        <InputText
          placeholder={"Email"}
          value={tableParams.filters.email || ""}
          onChange={(e) =>
            setTableParams((prevState) => ({
              ...prevState,
              filters: cleanObject({
                ...prevState.filters,
                email: e.target.value ? [e.target.value] : null,
              }),
            }))
          }
        />
      </div>
    </div>
  );
};

export default UserFilter;
