export const provideToken = (data) => {
  if (
    !data.userId ||
    data.userId.length !== 24 ||
    Number.isNaN(Number("0x" + data.userId))
  ) {
    return {
      success: false,
      message: "Invalid User.",
    };
  }
  if (
    !data.holdings ||
    Number.isNaN(Number(data.holdings)) ||
    Number(data.holdings) < 1
  ) {
    return { success: false, message: "Invalid token count" };
  }
  if (
    !data.orderPrice ||
    Number.isNaN(Number(data.orderPrice)) ||
    Number(data.orderPrice) < 0
  ) {
    return { success: false, message: "Invalid token price" };
  }
  return { success: true };
};
