import { NavLink } from "react-router-dom";
import styles from "../../styles/layout/Sidebar.module.scss";

const Sidebar = () => {
  return (
    <nav className={styles.sidebarContainer}>
      <div className={styles.navContainer}>
        <ul className={styles.navListItems}>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNavItem : styles.navItem
              }
              to="/"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNavItem : styles.navItem
              }
              to="/coins"
            >
              Coins
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNavItem : styles.navItem
              }
              to="/transactions"
            >
              Transactions
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNavItem : styles.navItem
              }
              to="/orders"
            >
              Orders
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNavItem : styles.navItem
              }
              to="/users"
            >
              Users
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNavItem : styles.navItem
              }
              to="/app-data"
            >
              App Data
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNavItem : styles.navItem
              }
              to="/nomoex-services"
            >
              Nomoex Services
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.activeNavItem : styles.navItem
              }
              to="/notifications"
            >
              Notifications
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
