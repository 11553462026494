import { Button } from "antd";
import styles from "../../styles/transactions/Transactions.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";

const OneTransactionActions = ({
  loading,
  markTxnAsFailed,
  markTxnAsSuccess,
}) => {
  return (
    <div className={styles.transactionActions}>
      <div className={commonStyles.actionButtons}>
        <Button
          loading={loading}
          className={commonStyles.primaryButton}
          onClick={markTxnAsFailed}
        >
          Mark as Failed
        </Button>
        <Button
          loading={loading}
          className={commonStyles.primaryButton}
          onClick={markTxnAsSuccess}
        >
          Mark as Success
        </Button>
      </div>
    </div>
  );
};

export default OneTransactionActions;
