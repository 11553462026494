import { Route, Routes, Navigate } from "react-router-dom";
import AppDataPage from "../pages/appData/AppDataPage";

const AppDataRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<AppDataPage />} />
      <Route path="*" element={<Navigate replace to="" />} />
    </Routes>
  );
};

export default AppDataRoute;
