import { InputNumber } from "antd";
import styles from "../../styles/common/Common.module.scss";

const NumberInput = ({
  placeholder,
  value,
  onChange,
  className,
  disabled,
  prefix,
  controls = true,
  onPressEnter,
}) => {
  const inputNumberStyle = className
    ? [styles.inputNumber, className].join(" ")
    : styles.inputNumber;

  return (
    <InputNumber
      size="large"
      placeholder={placeholder || null}
      value={value || null}
      onChange={onChange}
      onPressEnter={onPressEnter}
      className={inputNumberStyle}
      disabled={disabled || false}
      prefix={prefix || null}
      controls={controls && true}
    />
  );
};

export default NumberInput;
