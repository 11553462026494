import { Route, Routes, Navigate } from "react-router-dom";

const CoinsRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<p>All Coins</p>} />
      <Route path="/:id" element={<p>One Coins</p>} />
      <Route path="/add" element={<p>Add Coins</p>} />
      <Route path="/edit/:id" element={<p>Edit Coins</p>} />
      <Route path="*" element={<Navigate replace to="" />} />
    </Routes>
  );
};

export default CoinsRoute;
