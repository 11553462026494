import { useDispatch, useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { setAccessToken, logout } from "../redux/actions/authAction";

import axios from "axios";

const HTTP_METHOD = Object.freeze({
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  DELETE: "DELETE",
});

const useAPICall = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  const sendRequest = async (url, method, body) => {
    try {
      const headers = { "Content-Type": "application/json" };
      if (token) headers.authorization = `Bearer ${token}`;

      const options = {
        method: method,
        headers: headers,
        data: body,
        withCredentials: true,
        timeout: 10000,
      };

      const response = await axios(url, options);
      const { data } = response;

      if (
        response.headers["auth-token"] &&
        response.headers["auth-token"] !== "null"
      ) {
        const tokenData = jwtDecode(response.headers["auth-token"]);
        const userName = tokenData.userName;
        dispatch(
          setAccessToken({
            token: response.headers["auth-token"],
            userName: userName,
          })
        );
      } else if (
        response.headers["auth-token"] &&
        response.headers["auth-token"] === "null"
      ) {
        dispatch(logout());
      }

      return Object.assign({}, data, { statusCode: response.status });
    } catch (error) {
      if (error.response && error.response.data) {
        return error.response.data;
      }
      return { message: "Internal Server Error. Try again later." };
    }
  };

  const get = async (url) => {
    return await sendRequest(url, HTTP_METHOD.GET, null);
  };
  const post = async (url, body = {}) => {
    return await sendRequest(url, HTTP_METHOD.POST, body);
  };
  const patch = async (url, body = {}) => {
    return await sendRequest(url, HTTP_METHOD.PATCH, body);
  };
  const del = async (url, body = {}) => {
    return await sendRequest(url, HTTP_METHOD.DELETE, body);
  };

  // custom hook returns value
  return { get, post, patch, del };
};

export default useAPICall;
