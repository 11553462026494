import { useEffect, useState } from "react";
import { Button } from "antd";

import styles from "../../styles/appData/AppData.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";
import NumberInput from "../common/NumberInput";

const USDTPriceSection = ({
  loading,
  usdtPrice,
  setUsdtPrice,
  updateUSDTPrice,
}) => {
  const [editPrice, setEditPrice] = useState(false);
  const [prevValue, setPrevValue] = useState(0);

  const editPriceHandler = () => {
    setEditPrice(true);
  };

  const cancelEditPriceHandler = () => {
    setUsdtPrice(prevValue);
    setEditPrice(false);
  };

  const updatePriceHandler = async () => {
    setPrevValue(usdtPrice);
    await updateUSDTPrice();
    setEditPrice(false);
  };

  useEffect(() => {
    setPrevValue(usdtPrice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.sectionContainer}>
        <h2 className={styles.sectionHeading}>USDT Price</h2>
        <div className={styles.detailsContainer}>
          <div className={styles.details}>
            <p className={styles.detailTitle}>Price:</p>
            <NumberInput
              placeholder={"00"}
              value={usdtPrice}
              onChange={(value) => setUsdtPrice(value)}
              controls={false}
              disabled={!editPrice}
            />
          </div>
          <div className={commonStyles.actionButtons}>
            {!editPrice ? (
              <Button
                loading={loading}
                className={commonStyles.primaryButton}
                onClick={editPriceHandler}
              >
                Edit
              </Button>
            ) : (
              <>
                <Button
                  loading={loading}
                  className={commonStyles.secondaryButton}
                  onClick={cancelEditPriceHandler}
                >
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  className={commonStyles.primaryButton}
                  onClick={updatePriceHandler}
                >
                  Update
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default USDTPriceSection;
