import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { notification } from "antd";

import styles from "./styles/layout/App.module.scss";
import Header from "./components/layout/Header";
import Sidebar from "./components/layout/Sidebar";
import IndexRoute from "./routes/IndexRoute";
import { logoutAPI } from "./apis/authAPIs";
import useAPICall from "./hooks/useAPICall";

function App() {
  const { pathname } = useLocation();
  const { get } = useAPICall();
  const [api, contextHolder] = notification.useNotification();
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateDimension = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const logoutHandler = async () => {
    const response = await logoutAPI(get);
    if (!response.success) {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
  };

  return (
    <>
      {contextHolder}
      {width >= 960 ? (
        isLoggedIn ? (
          <>
            <Header logoutHandler={logoutHandler} />
            <div className={styles.bodyContainer}>
              <Sidebar />
              <div className={styles.contentContainer}>
                <IndexRoute />
              </div>
            </div>
          </>
        ) : (
          <IndexRoute />
        )
      ) : (
        <p>The app does not support for current screen size</p>
      )}
    </>
  );
}

export default App;
