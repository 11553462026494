import { Card } from "antd";
import styles from "../../../styles/users/Users.module.scss";
import moment from "moment";

const OneUserDetails = ({ userData }) => {
  return (
    <Card className={styles.card}>
      <h3>User Details</h3>
      <hr />
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>UID</p>
        <p className={styles.detailValue}>{userData.uid || "-"}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Name</p>
        <p className={styles.detailValue}>{userData.name || "-"}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Email</p>
        <p className={styles.detailValue}>{userData.email || "-"}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Phone</p>
        <p
          className={styles.detailValue}
        >{`${userData.countryCode}-${userData.phone}`}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Nickname</p>
        <p className={styles.detailValue}>{userData.nickname || "-"}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Address</p>
        <p className={styles.detailValue}>{userData.address || "-"}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Date of Birth</p>
        <p className={styles.detailValue}>
          {userData.dateOfBirth
            ? moment(userData.dateOfBirth).format("DD MMM YYYY")
            : "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>KYC Status</p>
        <p className={styles.detailValue}>
          {userData.isKycDone === "IP"
            ? "In Progress"
            : userData.isKycDone === "P"
            ? "Pending"
            : userData.isKycDone === "F"
            ? "Failed"
            : userData.isKycDone === "S"
            ? "Success"
            : "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Last Password Changed On</p>
        <p className={styles.detailValue}>
          {userData.passwordChanged
            ? moment(userData.passwordChanged).format("DD MMM YYYY, hh:mm a")
            : "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Active</p>
        <p className={styles.detailValue}>
          {userData.active === true
            ? "Yes"
            : userData.active === false
            ? "No"
            : "-"}
        </p>
      </div>
    </Card>
  );
};

export default OneUserDetails;
