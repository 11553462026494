import config from "../utils/config";
import { createQueryString } from "../utils/helper";

export const getAllOrderAPI = async (get, queryParam) => {
  const queryString = createQueryString(queryParam);
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/operator/orders${queryString}`
  );
  return response;
};

export const getOneOrderAPI = async (get, orderId) => {
  const response = await get(
    `${config.ORDERS_API_URL}/api/v1/orders/operator/order/${orderId}`
  );
  return response;
};
