export function cleanObject(obj) {
  const newObj = {};

  for (let propName in obj) {
    if (obj[propName] !== null && obj[propName] !== undefined) {
      newObj[propName] = obj[propName];
    }
  }
  return newObj;
}

export function createQueryString(params) {
  const queryArray = [];

  if (params.pagination && params.pagination.page)
    queryArray.push(`page=${params.pagination.page}`);
  if (params.pagination && params.pagination.limit)
    queryArray.push(`limit=${params.pagination.limit}`);
  if (params.pagination && params.pagination.orderBy)
    queryArray.push(`orderBy=${params.pagination.orderBy}`);
  if (params.pagination && params.pagination.sort)
    queryArray.push(`sort=${params.pagination.sort}`);
  if (params.filters && Object.keys(params.filters).length > 0) {
    for (let filterQuery in params.filters) {
      params.filters[filterQuery].forEach((el) =>
        queryArray.push(`${filterQuery}=${el}`)
      );
    }
  }

  const queryString = queryArray.length > 0 ? `?${queryArray.join("&")}` : "";

  return queryString;
}

export const convertObjectArrayToObject = (array, key = "_id") => {
  const object = {};

  for (let i = 0; i < array.length; i++) {
    object[array[i][key]] = array[i];
  }

  return object;
};

export const convertToInternationalCurrencySystem = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
    : Math.abs(Number(labelValue));
};

export const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};
