import { useState } from "react";
import { notification } from "antd";
import { loginAPI } from "../../apis/authAPIs";
import Login from "../../components/auth/Login";
import useAPICall from "../../hooks/useAPICall";

const LoginPage = () => {
  const { post } = useAPICall();
  const [api, contextHolder] = notification.useNotification();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const loginHandler = async () => {
    setLoading(true);
    const response = await loginAPI(post, loginData);
    if (response.success) {
      setLoginData({
        email: "",
        password: "",
      });
    } else {
      api["error"]({
        message: "Error!!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  return (
    <>
      {contextHolder}
      <Login
        loading={loading}
        loginData={loginData}
        setLoginData={setLoginData}
        loginHandler={loginHandler}
      />
    </>
  );
};

export default LoginPage;
