import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { notification } from "antd";

import styles from "../../styles/orders/Orders.module.scss";
import PageHeading from "../../components/common/PageHeading";
import useAPICall from "../../hooks/useAPICall";
import { getOneOrderAPI } from "../../apis/orderHistoryAPIs";
import OneOrderDetails from "../../components/orders/OneOrderDetails";
import OneOrderUserDetails from "../../components/orders/OneOrderUserDetails";
import OneOrderOtherInfo from "../../components/orders/OneOrderOtherInfo";

const OneOrderPage = () => {
  const { get } = useAPICall();
  const param = useParams();
  const [api, contextHolder] = notification.useNotification();

  const orderId = param.orderId;
  const [orderData, setOrderData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchOrder = async () => {
    setLoading(true);
    const response = await getOneOrderAPI(get, orderId);
    if (response.success) {
      setOrderData(response.data);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const reloadHandler = async () => {
    await fetchOrder();
  };

  return (
    <>
      {contextHolder}
      <PageHeading
        breadcrumb={[<Link to="/orders">Orders</Link>, " > ", orderId]}
        heading={`Order - ${orderId}`}
        isAddNew={false}
        reloadHandler={reloadHandler}
        showRefresh={true}
      />
      <div className={styles.orderDetailsContainer}>
        <OneOrderDetails orderData={orderData} loading={loading} />
        <OneOrderUserDetails orderData={orderData} />
        <OneOrderOtherInfo orderData={orderData} />
      </div>
    </>
  );
};

export default OneOrderPage;
