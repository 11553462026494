import config from "../utils/config";
import { createQueryString } from "../utils/helper";
import { updateKYCStatus } from "../validators/usersValidator";

export const getAllUsersAPI = async (get, queryParam) => {
  const queryString = createQueryString(queryParam);
  const response = await get(
    `${config.AUTH_API_URL}/api/v1/auth/operator/users${queryString}`
  );
  return response;
};

export const getOneUserByUserIdAPI = async (get, userId) => {
  const response = await get(
    `${config.AUTH_API_URL}/api/v1/auth/operator/user?_id=${userId}`
  );
  return response;
};

export const getOneUserByUidAPI = async (get, uid) => {
  const response = await get(
    `${config.AUTH_API_URL}/api/v1/auth/operator/user?uid=${uid}`
  );
  return response;
};

export const getOneUserKYCDataAPI = async (get, userId) => {
  const response = await get(
    `${config.AUTH_API_URL}/api/v1/auth/operator/users/${userId}/kyc/images`
  );
  return response;
};

export const updateKYCStatusAPI = async (patch, userId, data) => {
  const validate = updateKYCStatus(data);

  if (validate.success) {
    const response = await patch(
      `${config.AUTH_API_URL}/api/v1/auth/operator/users/${userId}/kyc/update-status`,
      data
    );

    return response;
  } else {
    return validate;
  }
};
