import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import TransactionsRoute from "./TransactionsRoute";
import CoinsRoute from "./CoinsRoute";
import OrdersRoute from "./OrdersRoute";
import UsersRoute from "./UsersRoute";
import AppDataRoute from "./AppDataRoute";
import LoginPage from "../pages/auth/LoginPage";
import NomoexServicesRoute from "./NomoexServicesRoute";

const IndexRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);

  return isLoggedIn ? (
    <Routes>
      <Route path="/" element={<p>Home</p>} />
      <Route path="/coins/*" element={<CoinsRoute />}></Route>
      <Route path="/transactions/*" element={<TransactionsRoute />} />
      <Route path="/orders/*" element={<OrdersRoute />} />
      <Route path="/users/*" element={<UsersRoute />} />
      <Route path="/app-data/*" element={<AppDataRoute />} />
      <Route path="/nomoex-services/*" element={<NomoexServicesRoute />} />
      <Route path="/notifications/*" element={<p>Notification</p>}></Route>
      <Route path="*" element={<Navigate replace to="" />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/auth" element={<LoginPage />} />
      <Route path="*" element={<Navigate replace to="auth" />} />
    </Routes>
  );
};

export default IndexRoute;
