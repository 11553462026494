import { Card } from "antd";
import styles from "../../styles/orders/Orders.module.scss";

const OneOrderUserDetails = ({ orderData }) => {
  return (
    <Card className={styles.card}>
      <h3>User Details</h3>
      <hr />
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Name</p>
        <p className={styles.detailValue}>
          {orderData.userDetail?.name || "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>UID</p>
        <p className={styles.detailValue}>{orderData.userDetail?.uid || "-"}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Email</p>
        <p className={styles.detailValue}>
          {orderData.userDetail?.email || "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Phone</p>
        <p className={styles.detailValue}>
          {orderData.userDetail?.phone || "-"}
        </p>
      </div>
    </Card>
  );
};

export default OneOrderUserDetails;
