import { useState } from "react";
import { Button, Card, Modal } from "antd";

import styles from "../../styles/nomoexServices/ProvideToken.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";
import InputText from "../common/InputText";
import NumberInput from "../common/NumberInput";

const ProvideNomoexTokenForm = ({
  loading,
  tokenData,
  setTokenData,
  selectedUser,
  provideTokenHandler,
}) => {
  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleProceed = async () => {
    setOpen(false);
    await provideTokenHandler();
  };

  return (
    <>
      <div className={styles.formContainer}>
        <Card className={styles.card}>
          <div className={styles.formItem}>
            <p className={styles.formTitle}>{"User Id (UID):"}</p>
            <InputText
              placeholder={"e.g. XYZ123"}
              value={tokenData.userUID}
              onChange={(e) =>
                setTokenData((prevState) => ({
                  ...prevState,
                  userUID: e.target.value,
                }))
              }
            />
            <div className={styles.formDetailContainer}>
              {selectedUser.name ? (
                <p
                  className={styles.formDetail}
                >{`${selectedUser.name} (${selectedUser.email}) - ${selectedUser.uid}`}</p>
              ) : (
                <p className={styles.formDetail}>No user selected</p>
              )}
            </div>
          </div>
          <div className={styles.formItem}>
            <p className={styles.formTitle}>Token Count:</p>
            <NumberInput
              placeholder={"e.g. 1000"}
              value={tokenData.holdings}
              onChange={(value) =>
                setTokenData((prevState) => ({
                  ...prevState,
                  holdings: value,
                }))
              }
              controls={false}
            />
          </div>
          <div>
            <div className={styles.formItem}>
              <p className={styles.formTitle}>Token Price:</p>
              <NumberInput
                placeholder={"e.g. 0.04"}
                value={tokenData.orderPrice}
                onChange={(value) =>
                  setTokenData((prevState) => ({
                    ...prevState,
                    orderPrice: value,
                  }))
                }
                controls={false}
              />
            </div>
          </div>
          <Button
            loading={loading}
            className={commonStyles.primaryButton}
            onClick={() => setOpen(true)}
          >
            Provide nomoex token
          </Button>
        </Card>
      </div>
      <Modal
        open={open}
        title="Provide Nomoex token"
        onCancel={handleCancel}
        footer={[
          <Button key="back" loading={loading} onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleProceed}
          >
            Proceed
          </Button>,
        ]}
      >
        <p>
          Do you confirm that filled details are correct and want to proceed to
          provide nomoex tokens
        </p>
      </Modal>
    </>
  );
};

export default ProvideNomoexTokenForm;
