import { Route, Routes, Navigate } from "react-router-dom";
import TransactionsPage from "../pages/transactions/TransactionsPage";
import OneTransactionsPage from "../pages/transactions/OneTransactionsPage";

const TransactionsRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<TransactionsPage />} />
      <Route path="/:txnId" element={<OneTransactionsPage />} />
      <Route path="*" element={<Navigate replace to="" />} />
    </Routes>
  );
};

export default TransactionsRoute;
