import { DatePicker } from "antd";
import dayjs from "dayjs";
import styles from "../../styles/common/Common.module.scss";

const { RangePicker } = DatePicker;

const InputDate = ({ className, placeholder, value, onChange, disabled }) => {
  const inputTextStyle = className
    ? [styles.inputText, className].join(" ")
    : styles.inputText;

  return (
    <RangePicker
      size="large"
      allowClear
      dateFormat="DD/MM/YYYY"
      placeholder={placeholder || ["Start Date", "End Date"]}
      className={inputTextStyle}
      value={[value[0] ? dayjs(value[0]) : "", value[1] ? dayjs(value[1]) : ""]}
      onChange={onChange}
      disabled={disabled || false}
    />
  );
};

export default InputDate;
