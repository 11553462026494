import { Card } from "antd";
import styles from "../../styles/users/Users.module.scss";

const UserPortfolio = ({ portfolio, nomoexHoldings }) => {
  return (
    <div className={styles.userDetailsContainer}>
      <Card className={styles.card}>
        <h3>{`User Portfolio (${portfolio.coins?.length || 0} coins)`}</h3>
        <hr />
        {portfolio.coins?.map((el, i) => (
          <div key={i} className={styles.coinDetails}>
            <img
              className={styles.coinIcon}
              src={el.coinDetail.coinIcon}
              alt={`${el.coinDetail.coinName} logo`}
            />
            <div>
              <p className={styles.detailValue}>{el.coinDetail.coinName}</p>
              <p className={styles.detailValue}>
                {el.holdings?.toFixed(6)} {el.coinDetail.coinSymbol}
              </p>
            </div>
          </div>
        ))}
      </Card>
      <Card className={styles.card}>
        <h3>Nomoex Holdings</h3>
        <hr />
        <div className={styles.detailContainer}>
          <p className={styles.detailValue}>Nomox</p>
          <p className={styles.detailValue}>
            {nomoexHoldings.holdings?.toFixed(6)} NOMOX
          </p>
        </div>
      </Card>
    </div>
  );
};

export default UserPortfolio;
