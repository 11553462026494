export const updateUSDTPrice = (data) => {
  if (
    !data.price ||
    Number.isNaN(Number(data.price)) ||
    Number(data.price) < 0
  ) {
    return { success: false, message: "Invalid USDT Price" };
  }
  return { success: true };
};

export const updateAppVersion = (data) => {
  if (!data.version || data.version.length < 5) {
    return { success: false, message: "Invalid app version" };
  }
  return { success: true };
};
