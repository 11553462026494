import config from "../utils/config";
import { login } from "../validators/authValidator";

export const loginAPI = async (post, data) => {
  const validate = login(data);

  if (validate.success) {
    const response = await post(
      `${config.AUTH_API_URL}/api/v1/auth/operator/login`,
      data
    );

    return response;
  } else {
    return validate;
  }
};

export const logoutAPI = async (get) => {
  const response = await get(
    `${config.AUTH_API_URL}/api/v1/auth/operator/logout`
  );

  return response;
};
