import PageHeading from "../../components/common/PageHeading";
import NomoexServicesList from "../../components/nomoexServices/NomoexServicesList";

const NomoexServicesPage = () => {
  return (
    <>
      <PageHeading
        breadcrumb={"Nomoex Services"}
        heading={"Nomoex Services"}
        isAddNew={false}
      />
      <NomoexServicesList />
    </>
  );
};

export default NomoexServicesPage;
