import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdOpenInNew } from "react-icons/md";

import PageHeading from "../../components/common/PageHeading";
import UserFilter from "../../components/users/UserFilter";
import { cleanObject } from "../../utils/helper";
import commonStyles from "../../styles/common/Common.module.scss";
import { Table, notification } from "antd";
import useAPICall from "../../hooks/useAPICall";
import { getAllUsersAPI } from "../../apis/userAPIs";

const UsersPage = () => {
  const { get } = useAPICall();
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      page: 1,
      limit: 10,
      orderBy: "_id",
      sort: "desc",
    },
    filters: {},
  });

  const fetchAllUsers = async () => {
    setLoading(true);
    const response = await getAllUsersAPI(get, tableParams);
    if (response.success) {
      setData(response.data);
      setTotal(response.metaData.totalUsers);
    } else {
      api["error"]({
        message: "Error!",
        description: response.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(tableParams)]);

  const reloadHandler = async () => {
    await fetchAllUsers();
  };

  const columns = [
    {
      title: "UserID",
      dataIndex: "_id",
      sorter: true,
    },
    {
      title: "UID",
      dataIndex: "uid",
      sorter: true,
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Phone",
      render: (record) => `${record.countryCode}-${record.phone}`,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "KYC Status",
      dataIndex: "isKycDone",
      filters: [
        {
          text: "In Progress",
          value: "IP",
        },
        {
          text: "Pending",
          value: "P",
        },
        {
          text: "Failed",
          value: "F",
        },
        {
          text: "Success",
          value: "S",
        },
      ],
      render: (isKycDone) =>
        isKycDone === "IP"
          ? "In Progress"
          : isKycDone === "P"
          ? "Pending"
          : isKycDone === "F"
          ? "Failed"
          : isKycDone === "S"
          ? "Success"
          : "",
    },
    {
      title: "Actions",
      render: (record) => (
        <Link to={`/users/${record._id}`}>
          <MdOpenInNew className={commonStyles.defaultIconStyle} />
        </Link>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams((prevState) => ({
      pagination: {
        page: pagination.current,
        limit: pagination.pageSize,
        orderBy: sorter?.order ? sorter.field : "_id",
        sort: sorter?.order === "ascend" ? "asc" : "desc",
      },
      total: prevState.total,
      filters: cleanObject({ ...prevState.filters, ...filters }),
    }));

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.limit) {
      setData([]);
    }
  };

  return (
    <>
      {contextHolder}
      <PageHeading
        breadcrumb={"Users"}
        heading={`Users (${total})`}
        isAddNew={false}
        reloadHandler={reloadHandler}
        showRefresh={true}
      />
      <UserFilter tableParams={tableParams} setTableParams={setTableParams} />
      <Table
        columns={columns}
        rowKey={(record) => record.txnId}
        dataSource={data}
        pagination={{
          current: tableParams.pagination?.page,
          pageSize: tableParams.pagination?.limit,
          total: total,
        }}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default UsersPage;
