import { Card } from "antd";
import styles from "../../styles/transactions/Transactions.module.scss";
import moment from "moment";

const OneTransactionDetails = ({ transactionData }) => {
  return (
    <Card className={styles.card}>
      <h3>Transactions Details</h3>
      <hr />
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Coin</p>
        <p className={styles.detailValue}>
          {`${transactionData.coinDetail?.coinName} (${transactionData.coinDetail?.coinSymbol})` ||
            "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Date</p>
        <p className={styles.detailValue}>
          {moment(transactionData.txnDate).format("DD MMM YYYY, hh:mm a")}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Status</p>
        <p className={styles.detailValue}>
          {transactionData.txnStatus === "IN"
            ? "Initiated"
            : transactionData.txnStatus === "IP"
            ? "In Progress"
            : transactionData.txnStatus === "F"
            ? "Failed"
            : transactionData.txnStatus === "S"
            ? "Success"
            : "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Transaction Type</p>
        <p className={styles.detailValue}>
          {transactionData.txnType === "deposit"
            ? "Deposit"
            : transactionData.txnType === "withdraw"
            ? "Withdraw"
            : transactionData.txnType === "vInvest"
            ? "Vaultx Invest"
            : transactionData.txnType === "vRedeem"
            ? "Vaultx Redeem"
            : transactionData.txnType === "fiat"
            ? "Fiat"
            : "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Transaction For</p>
        <p className={styles.detailValue}>
          {transactionData.txnFor === "crypto"
            ? "Crypto"
            : transactionData.txnFor === "fiat"
            ? "Fiat"
            : "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Deposit Address</p>
        <p className={styles.detailValue}>
          {transactionData.depositAddress || "-"}
        </p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Chain</p>
        <p className={styles.detailValue}>{transactionData.chain || "-"}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Holdings</p>
        <p className={styles.detailValue}>{transactionData.holdings || "-"}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Amount</p>
        <p className={styles.detailValue}>{transactionData.txnAmount || "-"}</p>
      </div>
      <div className={styles.detailContainer}>
        <p className={styles.detailTitle}>Fee</p>
        <p className={styles.detailValue}>{transactionData.fee || "-"}</p>
      </div>
    </Card>
  );
};

export default OneTransactionDetails;
