import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { TbReload } from "react-icons/tb";
import commonStyles from "../../styles/common/Common.module.scss";

const PageHeading = ({
  breadcrumb,
  heading,
  isAddNew = true,
  addNavigation,
  showRefresh = false,
  reloadHandler,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <p className={commonStyles.breadcrumb}>{breadcrumb}</p>
      <div className={commonStyles.pageHeading}>
        <div className={commonStyles.headingContainer}>
          <h1>{heading}</h1>
          {showRefresh && (
            <TbReload
              className={commonStyles.reloadIcon}
              onClick={reloadHandler}
            />
          )}
        </div>
        {isAddNew && (
          <Button
            className={commonStyles.successButton}
            onClick={() => navigate(addNavigation)}
          >
            Add New
          </Button>
        )}
      </div>
      <hr />
    </>
  );
};

export default PageHeading;
