import { Route, Routes, Navigate } from "react-router-dom";
import NomoexServicesPage from "../pages/nomoexServices/NomoexServicesPage";
import ProvideNomoexTokenPage from "../pages/nomoexServices/ProvideNomoexTokenPage";

const NomoexServicesRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<NomoexServicesPage />} />
      <Route
        path="/provide-nomoex-token"
        element={<ProvideNomoexTokenPage />}
      />
      <Route path="*" element={<Navigate replace to="" />} />
    </Routes>
  );
};

export default NomoexServicesRoute;
