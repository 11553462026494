import InputText from "../common/InputText";
import InputDate from "../common/InputDate";

import filterStyles from "../../styles/common/Filter.module.scss";
import { cleanObject } from "../../utils/helper";

const OrderFilter = ({ tableParams, setTableParams }) => {
  return (
    <div className={filterStyles.filterContainer}>
      <div className={filterStyles.filterProperty}>
        <p className={filterStyles.propertyTitle}>Order Id:</p>
        <InputText
          placeholder={"Order Id"}
          value={tableParams.filters.orderId || ""}
          onChange={(e) =>
            setTableParams((prevState) => ({
              ...prevState,
              filters: cleanObject({
                ...prevState.filters,
                orderId: e.target.value ? [e.target.value] : null,
              }),
            }))
          }
        />
      </div>
      <div className={filterStyles.filterProperty}>
        <p className={filterStyles.propertyTitle}>Date Range:</p>
        <InputDate
          value={[
            tableParams.filters.startDate || "",
            tableParams.filters.endDate || "",
          ]}
          onChange={(dates, dateString) => {
            setTableParams((prevState) => ({
              ...prevState,
              filters: cleanObject({
                ...prevState.filters,
                startDate: dateString[0] ? [dateString[0]] : null,
                endDate: dateString[1] ? [dateString[1]] : null,
              }),
            }));
          }}
        />
      </div>
    </div>
  );
};

export default OrderFilter;
