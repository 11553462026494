import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { HiOutlineUserCircle } from "react-icons/hi";
import styles from "../../styles/layout/Header.module.scss";
import commonStyles from "../../styles/common/Common.module.scss";
import { Button } from "antd";

const Header = ({ logoutHandler }) => {
  const { userName } = useSelector((state) => state.auth);

  return (
    <header className={styles.headerContainer}>
      <div className={styles.logoContainer}>
        <Link to="/">
          <img
            src="https://webapp-nomoex.s3.ap-south-1.amazonaws.com/nomoex_name/nomoex_name.png"
            alt="nomoex-logo"
            className={styles.logo}
          />
        </Link>
      </div>
      <div className={styles.profileContainer}>
        <p className={styles.profileName}>{userName}</p>
        <HiOutlineUserCircle className={styles.profileIcon} />
        <Button className={commonStyles.primaryButton} onClick={logoutHandler}>
          Logout
        </Button>
      </div>
    </header>
  );
};

export default Header;
