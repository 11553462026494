import styles from "../../styles/users/Users.module.scss";
import OneUserKYCActions from "./userDetails/OneUserKYCActions";
import OneUserDetails from "./userDetails/OneUserDetails";
import OneUserKYCImages from "./userDetails/OneUserKYCImages";

const UserDetails = ({
  userData,
  kycData,
  loading,
  markKYCAsFailed,
  markKYCAsSuccess,
}) => {
  return (
    <>
      {userData.isKycDone && userData.isKycDone === "IP" && (
        <OneUserKYCActions
          loading={loading}
          markKYCAsFailed={markKYCAsFailed}
          markKYCAsSuccess={markKYCAsSuccess}
        />
      )}
      <div className={styles.userDetailsContainer}>
        <OneUserDetails userData={userData} />
        <OneUserKYCImages kycData={kycData} />
      </div>
    </>
  );
};

export default UserDetails;
