import config from "../utils/config";
import { provideToken } from "../validators/nomoexServicesValidator";

export const provideNomoexTokenAPI = async (post, data) => {
  const validate = provideToken(data);

  if (validate.success) {
    const response = await post(
      `${config.ORDERS_API_URL}/api/v1/orders/operator/provide-nomoex-token`,
      data
    );

    return response;
  } else {
    return validate;
  }
};
